import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import Helmet from 'react-helmet'

export const CompanyPageTemplate = ({
  title,
  titleEn,
  description,
  helmet,
  content,
}) => (
  <main className="l-main">
    <div className="l-container">
      {helmet || ''}  
      <h1 className="c-pageTitle" data-spacingsp="mb7">
        <span className="c-pageTitle__container">
          <span className="c-pageTitle__textJa">{title}</span>
          <span className="c-pageTitle__textEn">{titleEn}</span>
        </span>
      </h1>
    </div>
    <div className="l-container--wide">
      <ul className="p-breadCrumbs">
        <li className="p-breadCrumbs__item"><Link to="/" className="p-breadCrumbs__link">ホーム</Link></li>
        <li className="p-breadCrumbs__item isCurrent">{title}</li>
      </ul>
    </div>
    <div className="l-container">
      <section className="p-mediumSection" data-spacingsp="pt7">
        <h2 className="p-mediumSection__title">
          <span className="p-mediumSection__titleEn">{content.section1.titleEn}</span>  
          <strong className="p-mediumSection__titleJa">{content.section1.titleJa}</strong>  
          <span className="p-mediumSection__titleSub js-textReplace">{content.section1.titleSub}</span>  
        </h2>
        <div className="p-mediumSection__body">
          <p className="p-mediumSection__copy">
            {content.section1.description}
          </p>  
        </div>
        <div className="c-triangleObject p-mediumSection__object--left">
          <div className="c-triangleObject__large--orange" />
          <div className="c-triangleObject__small--gray" />
        </div>
      </section>
      <section className="p-mediumSection--right">
        <h2 className="p-mediumSection__title--right">
          <span className="p-mediumSection__titleEn">{content.section2.titleEn}</span>  
          <strong className="p-mediumSection__titleJa u-font--26 u-font--sp18">{content.section2.titleJa}</strong>  
          <span className="p-mediumSection__titleSub js-textReplace">{content.section2.titleSub}</span>  
        </h2>
        <div className="p-mediumSection__body">
          <p className="p-mediumSection__copy u-alignRight u-alignSpLeft">
            {content.section2.description}
          </p>  
        </div>
        <div className="c-triangleObject p-mediumSection__object--right">
          <div className="c-triangleObject__large--aquaGreen" />
          <div className="c-triangleObject__small--gray" />
        </div>
      </section>
      <section className="p-mediumSection">
        <h2 className="p-mediumSection__title" data-spacingsp="pt6">
          <span className="p-mediumSection__titleEn">CORE VALUE</span>  
        </h2>
        <ol className="p-numList" data-spacing="pt4" data-spacingsp="pb1">
          <li className="p-numList__item">
            <p className="p-numList__copyJa">
              {content.section3.list1.titleJa}
            </p>
            <p className="p-numList__copyEn js-textReplace">
              {content.section3.list1.titleEn}
            </p>
          </li>
          <li className="p-numList__item">
            <p className="p-numList__copyJa">
              {content.section3.list2.titleJa}
            </p>
            <p className="p-numList__copyEn js-textReplace">
              {content.section3.list2.titleEn}
            </p>
          </li>
          <li className="p-numList__item">
            <p className="p-numList__copyJa">
              {content.section3.list3.titleJa}
            </p>
            <p className="p-numList__copyEn js-textReplace">
              {content.section3.list3.titleEn}
            </p>
          </li>
        </ol>  
        <div className="c-triangleObject p-mediumSection__object--left">
          <div className="c-triangleObject__large--gray" />
          <div className="c-triangleObject__small--marineBlue" />
        </div>

      </section>
    </div>
    <section className="p-footData">
      <div className="l-container">
        <h2
          className="c-sectionTitle u-flexSp--center"
          data-spacing="mb8"
          data-spacingsp="mb5"
        >
          <span className="c-sectionTitle__ja">会社概要</span>
          <span className="c-sectionTitle__en u-mqSpFull u-alignSpCenter">COMPANY DATA</span>
        </h2>
          <table className="p-footData__table">
            <tbody>
              <tr>
                <th className="p-footData__th">
                  {content.content1.title}
                </th>
                <td className="p-footData__td">
                  <strong className="u-font--medium">{content.content1.description}</strong>
                </td>
              </tr>
              <tr>
                <th className="p-footData__th">
                  {content.content2.title}
                </th>
                <td className="p-footData__td">
                  <strong className="u-font--medium">{content.content2.description}</strong>
                </td>
              </tr>
              <tr>
                <th className="p-footData__th">
                  {content.content3.title}
                </th>
                <td className="p-footData__td">
                  <strong className="u-font--medium">{content.content3.description}</strong>
                </td>
              </tr>
              <tr>
                <th className="p-footData__th">
                  {content.content4.title}
                </th>
                <td className="p-footData__td">
                  <strong className="u-font--medium">{content.content4.description}</strong>
                </td>
              </tr>
            </tbody>
        </table>
      </div>  
    </section>  
      
  </main>
)

CompanyPageTemplate.propTypes = {
  heading: PropTypes.string,
  headingEn: PropTypes.string,
  title: PropTypes.string,
  titleEn: PropTypes.string,
  description: PropTypes.string,
  helmet: PropTypes.object,
}


const CompanyPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  let schemaOrgJSONLD = [
    {
      '@context': 'https://schema.org',
      '@type': 'Corporation',
      '@id': "https://arbor.jp",
      url: "https://arbor.jp",
      name: "株式会社アーバ",
      alternateName: "株式会社アーバ",
      address: {
        '@type': 'PostalAddress',
        'postalCode': '372-0012',
        'addressCountry': 'JP',
        'addressRegion': '群馬県',
        'addressLocality': '伊勢崎市',
        'streetAddress': '豊城町2144−3',
      },
      founder: {
        '@type': 'Person',
        'givenName': '直人',
        'familyName': '石丸',
      },
      foundingDate: '2018-1-11',
      description: frontmatter.description,
    },
    {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: [
        {
          '@type': 'ListItem',
          name: "トップページ",
          position: 1,
          item: {
            "@id": "https://arbor.jp",
            "name": "株式会社アーバ",
          }
        },
        {
          '@type': 'ListItem',
          position: 2,
          item: {
            "@id": typeof window !== "undefined" && window.location.href,
            "name": frontmatter.title,
          }
        },
      ],
    },
  ]; 

  return (
    <Layout>
      <CompanyPageTemplate
        title={frontmatter.title}
        titleEn={frontmatter.titleEn}
        description={frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | 株式会社アーバ">
            <title>{`${frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${frontmatter.description}`}
            />
            <script type="application/ld+json">{JSON.stringify(schemaOrgJSONLD)}</script>
          </Helmet>
        }
        content={frontmatter.content}
      />
    </Layout>
  )
}

CompanyPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default CompanyPage

export const CompanyPageQuery = graphql`
  query CompanyPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        titleEn
        description
        content{
          section1{
            titleEn
            titleJa
            titleSub
            description
          }
          section2{
            titleEn
            titleJa
            titleSub
            description
          }
          section3{
            titleEn
            list1{
              titleJa
              titleEn
            }
            list2{
              titleJa
              titleEn
            }
            list3{
              titleJa
              titleEn
            }
          }
          content1{
            title
            description
          }
          content2{
            title
            description
          }
          content3{
            title
            description
          }
          content4{
            title
            description
          }
        }
      }
    }
  }
`
