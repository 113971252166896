import React from 'react'
import { Link } from 'gatsby'
import logo from '../img/logo.svg'
import scrollAddClass from './scrollAddClass'
import GlobalNavigation from './globalNavigation'
import GlobalNavAccordionButton from './globalNavAccordion'

scrollAddClass()

const Navbar = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      navBarActiveClass: '',
    }
  }


  toggleHamburger = () => {
  // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
            navBarActiveClass: 'is-active',
          })
          : this.setState({
            navBarActiveClass: '',
          })
      }
    )
  }

  render() {
    const { isOpen } = this.state;

    return (
      <header className="l-header js-scrollEvent">
        <div className="l-container--wide l-header__container">
          <Link to="/" className="p-headLogo js-scrollEvent">
            <div className="p-headLogo__topObject">
              <div className="c-triangleObject--180deg">
                <div className="c-triangleObject__large--yellow" />
                <div className="c-triangleObject__small--orange" />
              </div>
            </div>
            <figure className="p-headLogo__logo">
              <img className="p-headLogo__img" src={logo} alt="アーバロゴマーク" />
            </figure>
            <p className="p-headLogo__name">
              <span className="u-flex--spaceBetween">
                <span>株</span>
                <span>式</span>
                <span>会</span>
                <span>社</span>
              </span>
              <span className="u-flex--spaceBetween">
                <span>ア</span>
                <span>ー</span>
                <span>バ</span>
              </span>
            </p>
            <div className="p-headLogo__text u-font--termina">
              <p className="u-font--light" data-spacing="mb1">
                &#091; YOUR TEAM &#093;
              </p>
              <p className="p-headLogo__subText u-font--light u-font--14 u-mqHidden--sm">
                System Developer
              </p>
            </div>
            <div className=" p-headLogo__bottomObject">
              <div className="c-triangleObject">
                <div className="c-triangleObject__large--aquaGreen" />
                <div className="c-triangleObject__small--marineBlue" />
              </div>
            </div>
          </Link>
          <GlobalNavigation
            NavClass={`p-headNav ${this.state.navBarActiveClass}`}
            dataHamburger={() => { this.toggleHamburger() }}
          />
        </div>
        <div
          className={`c-humbergur ${this.state.navBarActiveClass}`}
          data-target="p-headNav"
          onClick={() => this.toggleHamburger()}
        >
          <span className="c-humbergur__bar--top" />
          <span className="c-humbergur__bar--middle" />
          <span className="c-humbergur__bar--bottom" />
        </div>
      </header>
    )
  }
}

export default Navbar
